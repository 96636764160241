import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import AwardBadge from "../images/award-badge.svg";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "mindfulness.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div>
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="WELL WOMEN CARE" />
            <h2 className="font-display md:text-display-xl text-display-md pb-4">
              Caring for women is our <span className="italic">passion</span>.
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Making Mommies' comfortable environment is a welcome alternative to a doctor’s office for annual exams, pap smears, 
              clinical breast exams and more. With an emphasis on nutrition, preventative medicine, fitness and early detection, 
              our well-woman visits are personalized to your needs. Preconception counseling, birth control options and testing 
              for sexually transmitted infections are included.
            </p>

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Here are a few ways our licensed midwives are here for you: <br />
              <span class='mx-2'>&#8226;</span>Preconception counseling<br />
              <span class='mx-2'>&#8226;</span>Annual pap smears<br />
              <span class='mx-2'>&#8226;</span>Full physicals<br />
              <span class='mx-2'>&#8226;</span>Non-hormonal birth control options<br />
              <span class='mx-2'>&#8226;</span>Breast examinations<br />
              <span class='mx-2'>&#8226;</span>Confidential HIV & STD testing<br />
            </p>

          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative">
            <GatsbyImage
              image={getImage(data.aboutimage)}
              alt="Interior Design"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
