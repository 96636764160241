import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import AwardBadge from "../images/award-badge.svg";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "baby.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div>
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="MATERNITY CARE" />
            <h2 className="font-display md:text-display-xl text-display-md pb-4">
              Birth is not only about making babies. Birth is about making <span className="italic">mothers</span>.
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              We help make strong, competent, capable mothers who trust themselves and know their inner-strenth.
              As experts in normal pregnancy and birth, midwives monitor the physical well being of you and your baby.  
              We also address important psychological and emotional facets of this life-changing journey. 
              The care you receive during your pregnancy and in the weeks following your birth is tailored to the unique needs and circumstances of you and your family.
            </p>

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Each one of our appointments is an hour-long, personalized session with our midwives. You can expect to see us: <br /><br />
              <span class='mx-2'>&#8226;</span> Once per month through your 28th week of pregnancy.<br />
              <span class='mx-2'>&#8226;</span> Every 2 weeks from 28 to 36 weeks of pregnancy.<br />
              <span class='mx-2'>&#8226;</span> Weekly visits from 36 weeks until the birth of your baby.<br />
              <span class='mx-2'>&#8226;</span> At your home between 24-48 hours after your birth.<br />
              <span class='mx-2'>&#8226;</span> Back in our office for visits at 7 days, 3 weeks, and 6 weeks postpartum.<br />
            </p>

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              During each visit, we provide any necessary lab work, including blood draws, cultures and pap smears. 
              Our postpartum visits include screenings for postpartum depression, newborn exams, weight checks, and extensive lactation support.
            </p>

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Statistically the safest place for a low-risk woman to give birth in an out of hospital setting with a trained and licensed midwife. 
              Midwives are licensed healthcare providers by the state of Arizona and during a natural birth, midwives perform clinical and medical tasks 
              such as monitoring the baby’s heart rate, doing vaginal examinations, and assessing mother’s vital signs.
            </p>

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              <span class='font-semibold'>Home Birth</span><br />
              In the comfort of your home you’ll experience labor, delivery and compassionate postpartum care.  
              Home birth is a great option that brings families together, while also bringing your newborn into the world 
              where they will first call home. There is nothing more special than beginning to create memories with your child in your home.
            </p>

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              <span class='font-semibold'>Water Birth</span><br />              
              Water birth is referred to by most midwives as the “gentlest of gentle births.” Embraced by warm, gravity-defying water, often in a tub customized 
              for the safe and comfortable delivery of your baby, mothers who choose water birth report a reduction in pain, lessening of anxiety, and a marked increase in relaxation.<br /><br />
              Home birth parents may choose to rent or buy a temporary “birthing pool.”  We will assist you in selecting the right tub for your natural birth needs.<br /><br />
            </p>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative">
            <GatsbyImage
              image={getImage(data.aboutimage)}
              alt="Interior Design"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
