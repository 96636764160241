import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import AwardBadge from "../images/award-badge.svg";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "breastfeeding.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div>
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-8 relative lg:order-first order-last">
            <GatsbyImage
              image={getImage(data.aboutimage)}
              alt="Interior Design"
            />
          </div>
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="BREASTFEEDING + LACTATION" />
            <h2 className="font-display md:text-display-xl text-display-md pb-4">
              Breastfeeding has significant nutritional, physical and <span class='italic'>emotional</span> benefits for mom and baby.
            </h2>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Breastfeeding is proven to reduce the number of ear infections, improve immune function, reduce the risk of obesity, asthma, and cancer. 
              The emotional bonds and benefits cannot be understated. Moms who nurse report fewer incidences of postpartum depression, better bonding with their baby, 
              and the confidence and convenience that comes with knowing that you possess everything your baby needs to thrive.
            </p>

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              The American Academy of Pediatrics and other well-respected health organizations all agree that breast milk is the best food for babies during the important first year of life. 
              However, it is very common for mothers to have many questions concerning lactation and breastfeeding. That’s why it’s so important to find lactation consultants they can trust.
            </p>

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Lactation support for your home birth or experience with Making Mommies will include: <br />
              <span class='mx-2'>&#8226;</span>Monthly breastfeeding class<br />
              <span class='mx-2'>&#8226;</span>Breastfeeding assistance from an International Board Certified Lactation Consultant immediately after birth to ensure that mom and baby are off to the right start.<br />
              <span class='mx-2'>&#8226;</span>Breastfeeding consultations and weight checks at each post-partum visit to be certain that you and your baby continue to nurse successfully.<br />
              <span class='mx-2'>&#8226;</span>Phone support regarding breastfeeding and nutrition throughout your baby’s first year of life.<br />
            </p>

            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              <span class='font-medium'>A lactation consultant or other support might be covered by your insurance.</span>We work with The Lactation Network (TLN) to help you get the support you need.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
