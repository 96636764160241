import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import AwardBadge from "../images/award-badge.svg";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "about-creative.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div>
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-12 flex flex-col gap-6">
            <Eyebrow label="FAQ" />
            <h2 className="font-display md:text-display-xl text-display-md pb-4">
              Frequently Asked Questions
            </h2>
            <p className="text-body-xl font-light text-neutral-700">
              <div class='text-display-sm font-medium my-4'>What is Midwifery?</div>
              
              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Midwives provide care for women and childbearing families.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Midwives encourage realistic expectations of childbirth by women within their own society, with the minimum expectation that no women should be harmed by conception or childbearing.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Midwives use their professional knowledge through training and professional credentials to ensure safe birthing practices in all environments and cultures.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Midwives respond to the psychological, physical, emotional, and spiritual needs of women seeking health care, whatever their circumstances.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Midwives actively seek personal, intellectual, and professional growth throughout their midwifery career, integrating this growth into practice.</span>  
              </div>
            </p>

            <p className="text-body-xl font-light text-neutral-700">
              <div class='text-display-sm font-medium my-4'>What’s the difference between a midwife and a doula?</div>
              
              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Birth doulas offer emotional support, encouragement, and wisdom throughout labor and birth. 
                  Postpartum doulas support women and families through the transformation that a new baby brings to a family. 
                  Doulas do not provide clinical or medical services. </span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Midwives are trained and licensed healthcare professionals who provide maternity and gynecological care. 
                  All of our midwives are licensed by the medical board and are responsible for mom and baby’s health and safety throughout pregnancy, 
                  labor and postpartum, just like a doctor or OBGYN.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Midwives specialize in normal pregnancy, birth, and postpartum care for women and babies. They believe in a woman’s ability to give birth naturally. 
                  When working with a licensed midwife, a woman will have continuity of care throughout her pregnancy, labor, delivery and postpartum experience. 
                  She is more likely to be treated as an individual with unique needs and preferences.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>She is much less likely to need pain management medication, and the risk of cesarean section is greatly reduced.
                  She will not be subjected to routine procedures, and she is less likely to experience complications or require interventions.</span>  
              </div>
            </p>

            <p className="text-body-xl font-light text-neutral-700">
              <div class='text-display-sm font-medium my-4'>Why should I hire a midwife?</div>
              
              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Midwives are experts in normal childbirth. They believe in the woman’s ability to give birth naturally. 
                  Statistically, the safest place for a low-risk woman to give birth is at home or in a birth center with a trained midwife.  </span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>When working with a trained midwife, a woman can eat and drink and move around freely. 
                  She will have continuity of care throughout her pregnancy, labor, delivery, and postpartum experience. 
                  She is more likely to be treated and her progress evaluated as an individual, rather than being sacrificed to protocols or statistical averages.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>She is much less likely to need pain medications, and the risk of cesarean section is greatly reduced. 
                  She will not be subjected to routine procedures such as continuous electronic monitoring or routine use of IV fluids, and she’ll see a decrease in postpartum depression.</span>  
              </div>
            </p>

            <p className="text-body-xl font-light text-neutral-700">
              <div class='text-display-sm font-medium my-4'>What are the health requirements for out of hospital birth?</div>
              
              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>If you are having a normal pregnancy, with no complications or major pre-existing conditions, you are considered low risk, and are a candidate for out-of-hospital birth.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Each of our clients is evaluated to ensure that out-of-hospital birth is the best choice for them.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Contact us to discuss your specific circumstances or to set up a free consultation with our midwives.</span>  
              </div>
            </p>

            <p className="text-body-xl font-light text-neutral-700">
              <div class='text-display-sm font-medium my-4'>Can I have an out of hospital birth if I’ve had a previous c-section?</div>
              
              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>If you’ve had one previous c-section and it was over 12 months ago, you may be able to come into our care.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>If you’ve had multiple c-sections, we refer you to an appropriate care provider.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>We are committed to supporting women in finding the best care for their individual needs.</span>  
              </div>
            </p>

             <p className="text-body-xl font-light text-neutral-700">
              <div class='text-display-sm font-medium my-4'>What happens if I need to go to the hospital?</div>
              
              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Midwives are well-trained to handle the very rare unforeseen emergency.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Midwives carry emergency medications and equipment and are trained and certified in Neonatal Resuscitation, Basic Life Support, Advanced Life Support and Obstetrics (ALSO), and Advanced Fetal Monitoring.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Despite our best efforts, complications do arise. An individualized transfer-of-care plan is established during your prenatal care.This plan designates a specific collaborating physician and hospital that will accept you if a means of transport to a hospital is necessary. Although at that point, our midwives are no longer the primary care provider, we will remain with you and function as in-hospital labor support.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Most transports are not "911" emergencies, so we put the mother in the car and drive to the hospital that we have previously designated.</span>  
              </div>
            </p>

            <p className="text-body-xl font-light text-neutral-700">
              <div class='text-display-sm font-medium my-4'>Do you monitor the baby during labor?</div>
              
              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Midwives carefully monitor you and your baby’s safety in accordance with the standards set by the American College of Obstetricians and Gynecologists (ACOG).  We practice intermittent fetal monitoring.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Hospitals use continuous fetal monitoring. The evidence against continuous electronic fetal monitoring is so clear that the U.S. Preventive Services Task Force issued a recommendation saying that continuous electronic fetal monitoring should NOT be used in low-risk women. Even ACOG has endorsed intermittent auscultation as an “appropriate and safe alternative” to electronic fetal monitoring.</span>  
              </div>
            </p>

            <p className="text-body-xl font-light text-neutral-700">
              <div class='text-display-sm font-medium my-4'>Who is in attendance at my birth?</div>
              
              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>For the safety of the mother and the baby, we have two midwives, or one midwife and a senior midwifery student, attend each birth.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Each is trained in Neonatal Resuscitation, Basic Life Support and Advanced Life Support and Obstetrics (ALSO), and Advanced Fetal Monitoring. All of our midwives have extensive experience in home births and water births.</span>  
              </div>
            </p>

            <p className="text-body-xl font-light text-neutral-700">
              <div class='text-display-sm font-medium my-4'>Can my older child(ren) be present at the birth?</div>
              
              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>You can have whomever you feel comfortable with at your birth. Children generally do great with natural birth, especially when they’ve been adequately prepared.</span>  
              </div>
            </p>

            <p className="text-body-xl font-light text-neutral-700">
              <div class='text-display-sm font-medium my-4'>Do you offer a payment plan?</div>
              
              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>We are a cash pay practice and offer a flexible payment schedule.  We do ask that we are paid in full by your 36th week of pregnancy.  Please call our office if your situation requires further accommodation.</span>  
              </div>
              
              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>We can also bill your insurance as an out-of-network provider.</span>  
              </div>
            </p>

            <p className="text-body-xl font-light text-neutral-700">
              <div class='text-display-sm font-medium my-4'>Does insurance cover the cost of having a baby with an out of hospital midwife?</div>
              
              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Most PPO, POS, and cost sharing insurance plans (Samaritan, Medi-Share) will cover a portion of your birth, depending on your deductible and out of network benefits.</span>  
              </div>

              <div class='flex flex-row mb-3'>
                <span class='mx-2 text-display-xs'>&#8226;</span>
                <span>Before you begin care, we will help you verify your benefits so you will have a good indication of what your plan may pay.</span>  
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
