import React from "react";

import Seo from "../components/seo";
import Layout from "../components/layout";
import Maternity from "../components/servicesMaternity";
import Lactation from "../components/servicesLactation";
import Well from "../components/servicesWellWomen";
import FAQ from "../components/servicesFAQ";


const IndexPage = () => (
  <Layout>
    <Seo />
    <Maternity />
    <Lactation />
    <Well />
    <FAQ />
  </Layout>
);

export default IndexPage;
